import React from 'react';

function Loader(){
	return(
		<section style={{"backgroundColor":"#212529","display":"flex","justifyContent":"center","alignItems":"center","width":"100%","minHeight":"100vh"}}>
			<div className="spinner-border text-success" role="status">
			</div>
		</section>
	);
}

export default Loader;
