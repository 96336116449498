import React,{Component} from 'react';
import {API_BASE} from '../../config';
import Navigator from '../Navigator';

import AppContext from '../../context/app-context';
import NavigatorMobile from '../../components/NavigatorMobile';

function renderList(checked){
	if(checked){
		return(
			<NavigatorMobile/>
		);
	}
	return null;
}

export default class Sites extends Component {

	static contextType = AppContext;

	constructor(){
		super();
		this.state = {
			sites: null
		}
	}	
	componentDidMount(){
		fetch(`${API_BASE}/sites`)
  		.then(response => response.json())
  		.then(data => this.setState({sites:data}))
		.catch(error => this.setState({error}));
	}
	ubicar({id}){
		this.props.history.push(`/location/${id}`);
	}
	agregar(){
		this.props.history.push('/newsite');
	}
	editar({id}){
		this.props.history.push(`/editsite/${id}`);
	}
	eliminar({id}){
		fetch(`${API_BASE}/sites/${id}`, {
  			method: "DELETE",
  			headers: {"Content-type": "application/json"}
		})
		.then(response => {
			if(response.ok){
				this.props.history.push('/');
			}
		}) 
		.catch(err => console.log(err));
	}
	render(){
		const {sites} = this.state;
		return(
		<section className="contenedor">
			{renderList(this.context.checked)}
			{(!this.context.checked) ? (
			<div className="cuerpo mb-3">
				<div className="card">
					<div className="card-header">
						<h2>Sitios</h2>
					</div>
					<div className="card-body">
					{sites ? (
					<ul className="list-group mb-3">{sites.map(s => 
						<li className="list-group-item" key={s._id}>{s.name} <button className="btn btn-danger" onClick={()=>this.eliminar({id:s._id})} style={{"marginRight":"1em",marginLeft:"1em"}}>Eliminar</button><button className="btn btn-warning" onClick={()=>this.editar({id:s._id})} style={{"marginRight":"1em"}}>Editar</button><button className="btn btn-info" onClick={()=>this.ubicar({n:s.name,id:s._id})}>Ubicar</button>
						</li>
					)}
					</ul>
					):(null)}
					<button onClick={()=>this.agregar()} className="btn btn-primary">Agregar sitio</button>
					</div>
				</div>
			</div>
			) : (null) }
		</section>
		);
	}
}
