import React,{Component} from 'react';
import Error from '../Error';
import Loader from '../Loader';
import {API_BASE} from '../../config';
import Navigator from '../Navigator';

import AppContext from '../../context/app-context';
import NavigatorMobile from '../../components/NavigatorMobile';

function renderList(checked){
	if(checked){
		return(
			<NavigatorMobile/>
		);
	}
	return null;
}

async function getSiteAsync(id){
                let response = await fetch(`${API_BASE}/sites/${id}`);
                let data = await response.json()
                return data;
        }

async function getKitsAsync(url)
{
  let response = await fetch(url);
  let data = await response.json()
  return data;
}


export default class EditSite extends Component {

	static contextType = AppContext;
	constructor(){
		super();
		this.state = {
			loading: true,
			error: null,
			site: null,
			kits: null,
			editable: false,
			withHour: false,
			items: [],
			type: '',
			nameKit: '',
			loaded: false,
			generator: null,
			hours: 0,
			minutes: 0
		};
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChangeName = this.handleChangeName.bind(this);
		this.handleChangeIP = this.handleChangeIP.bind(this);
		this.addKitToSite = this.addKitToSite.bind(this);
		this.handleChangeNamekit = this.handleChangeNameKit.bind(this);
	}
	
	handleChangeIPGenerator(e){
		this.setState({generator:{...this.state.generator,ip:e.target.value}});
	}

	handleChangeNameGenerator(e){
		this.setState({generator:{...this.state.generator,name:e.target.value}});
	}

	handleChangeEstimatedDurationGenerator(e){
		this.setState({generator:{...this.state.generator,estimated_duration:e.target.value}});
	}

	componentDidMount(){
		getSiteAsync(this.props.match.params.id)
		.then(data => {
			if(data.items&&data.generator){
				if(data.generator.estimated_duration&&data.estimated_duration!==0){
					let a = (data.generator.estimated_duration/60)*100;
					let b = parseInt(data.generator.estimated_duration/60)*100;
					let c = Math.round(((a-b)/100)*60);
					this.setState({
						site:data,
						items: data.items||[],
						loaded: true,
						generator: data.generator,
						hours: parseInt(data.generator.estimated_duration/60),
						minutes: c
					});
				}else{
					this.setState({
                                                site:data,
                                                items: data.items||[],
                                                loaded: true,
                                                generator: data.generator,
                                                hours: 0,
                                                minutes: 0
                                        });
				}
			}else{
				this.setState({site:data,items:data.items||[]});
			}
		})
		.then(()=>
			getKitsAsync(`${API_BASE}/kits`)
			.then(data => this.setState({kits:data,type:data[0].name,loading:false}))
		)
		.catch(error => this.setState({error}))
	}

	addKitToSite(e){
		this.setState(state => ({
			ip: '',
			nameKit: '',
			type: this.state.kits[0].name,
      			items: state.items.concat({ip:this.state.ip,type:this.state.type,name:this.state.nameKit}),
    		}));
		e.preventDefault();
	}
	
	removeKitToSite(id){
		var filtered = this.state.items.filter(function(value, index, arr){ 
        		return index !== id;
    		});
		this.setState({items:filtered});
	}

	handleChangeName(e){this.setState({site:{...this.state.site,name:e.target.value}})};
	handleChangeIP(e){this.setState({site:{...this.state.site,ip:e.target.value}})};
	handleChangeNameKit(e){this.setState({nameKit:e.target.value})};
	handleSubmit(e){
		let generator = this.state.generator;
		let data = {
                        id: this.props.match.params.id,
                        name: this.state.site.name,
                        items: this.state.items,
                }
		if(generator){
			let min_total = parseInt(this.state.hours*60)+parseInt(this.state.minutes);
			if(this.state.hours===0&&this.state.minutes===0&&(!this.state.withHour&&!this.state.editable)){
				generator.estimated_by = 'server';	
				generator.estimated_duration = 0;
			}else{
				generator.estimated_by = localStorage.getItem('email');
				generator.estimated_duration = min_total;
			}
			data.generator = generator;
		}
                fetch(`${API_BASE}/sites`, {
                        method: "PUT",
                        body: JSON.stringify(data),
                        headers: {"Content-type": "application/json"}
                })
                .then(response => {
                        if(response.ok){
                                this.props.history.push('/');
                        }
                })
                .catch(err => console.log(err));
		e.preventDefault();
	}
	

	handleChangeType(e){this.setState({type:e.target.value})}
	
	handleChangeNewIP(e){this.setState({ip:e.target.value})}

	eliminarGenerador(){
		this.setState({generator:null,withHour:false,editable:false});
	}

	agregarGenerador(){
		this.setState({editable:true,withHour:false,loaded:false,generator:{...this.state.generator,ip:'',name:'',estimated_duration:''}});
	}
	
	agregarGeneradorDefecto(){
		this.setState({editable:true,withHour:true,loaded:false,hours:8,minutes:0,generator:{...this.state.generator,ip:'',name:'',estimated_duration:''}});
	}

	agregarGeneradorSinHora(){
		this.setState({withHour:false,editable:false,loaded:false,generator:{...this.state.generator,ip:'',name:'',estimated_duration:''}});
	}

	onChangeHours(e){this.setState({hours:e.target.value})}
	onChangeMinutes(e){this.setState({minutes:e.target.value})}
	
	render(){
		const {loading,error,site,kits,ip,type,items,generator} = this.state;
		if(error) return <Error error={error}/>;
		if(loading) return <Loader/>
		return(
		<section className="contenedor">

			{renderList(this.context.checked)}
			{(!this.context.checked) ? (
			<div className="cuerpo mb-3">
			<div className="card">
				<div className="card-header">
					<h2>Editar</h2>
				</div>
				<div className="card-body">
					<div>
						<div className="card mb-3">
						<div className="card-body">
						<div className="mb-3">
							<fieldset>
								<label htmlFor="nameInputEditSite" className="form-label">Nombre</label>
								<input id="nameInputEditSite" className="form-control" value={site.name} onChange={this.handleChangeName}/>
							</fieldset>
						</div>
						</div>
						</div>
						<div className="card mb-3">
							<div className="card-header">
								<h4>Equipos</h4>
							</div>
							<div className="card-body">
							<form onSubmit={this.addKitToSite} className="mb-3">
								<fieldset>
									<label className="form-label">Tipo</label>
									<select className="form-control" value={type} onChange={this.handleChangeType.bind(this)}>
										{kits.map((k,i) => <option value={k.name}key={i}>{k.name}</option>)}
									</select>
								</fieldset>
								<fieldset>
									<label className="form-label">IP</label>
									<input className="form-control" value={ip} onChange={this.handleChangeNewIP.bind(this)} required/>
								</fieldset>
								<fieldset className="mb-3">
									<label className="form-label">Nombre del equipo</label>
									<input className="form-control" value={this.state.nameKit} onChange={this.handleChangeNameKit.bind(this)} required/>
								</fieldset>
								<button className="btn btn-secondary">Agregar equipo</button>
							</form>
							<ul className="list-group">
								{items.map((k,i) => 
			 					<li className="list-group-item" key={i}>
										Tipo: {k.type} | IP: {k.ip} | Nombre: {k.name} <button className="btn btn-danger" onClick={()=>this.removeKitToSite(i)} style={{"marginLeft":"1em"}}>Eliminar</button>
								</li>)
								}
							</ul>
							</div>
						</div>
						<div className="card">
							<div className="card-header">
								<h4>Equipo electrógeno</h4>
							</div>
							<div className="card-body">
								<h3>Aclaraciones</h3>
								<p>
								*La duración por defecto será de 8 hs(recuerde revisar que ese sea el tiempo exacto de duración o elija otra opción)
								</p>
								<p>
								*Las estimaciones del tiempo de duración de los equipos electrógenos se hace mediante el comando ping, y se realizarán en todo momento que el equipo se encuentre encendido, recuerde que la primera estimación se hará cuando el equipo electrógeno de respuesta al comando ping por primera vez hasta que deje de darlo, siendo este el tiempo que dura la estimación, cada dia a las 00:00 hs se actualizará el valor de la duración estimada, si es mayor a algún valor anterior, este se sobreescribirá por el mayor.
								</p>
						{generator ? (
							<form className="mb-3">
								<fieldset>
									<label className="form-label">IP asociada</label>
									<input className="form-control" value={generator.ip} onChange={this.handleChangeIPGenerator.bind(this)}/>
								</fieldset>
								<fieldset>
									<label className="form-label">Nombre</label>
									<input className="form-control"value={generator.name} onChange={this.handleChangeNameGenerator.bind(this)}/>
								</fieldset>
								{ (!this.state.withHour&&this.state.editable) ? (
									 <fieldset>
                                                                        	<label className="form-label">Duración:</label>
                                                                                <fieldset className="mt-2">
                                                                                        <label className="form-label">Horas:</label>
                                                                                        <input className="form-control" type="number" value={this.state.hours} onChange={this.onChangeHours.bind(this)} min={1}/>
                                                                                </fieldset>
                                                                                <fieldset className="mt-2">
                                                                                        <label className="form-label">Minutos:</label>
                                                                                        <input className="form-control" type="number" value={this.state.minutes} onChange={this.onChangeMinutes.bind(this)} min={0} max={59}/>
                                                                                </fieldset>
                                                                	</fieldset>
								) : (null) }
								{ (this.state.withHour&&this.state.editable) ? (
									<fieldset>
                                                                        	<label className="form-label">Duración:</label>
                                                                                <fieldset className="mt-2">
                                                                                        <label className="form-label">Horas:</label>
                                                                                        <input disabled className="form-control" type="number" value={this.state.hours} onChange={this.onChangeHours.bind(this)} min={1}/>
                                                                                </fieldset>
                                                                                <fieldset className="mt-2">
                                                                                        <label className="form-label">Minutos:</label>
                                                                                        <input disabled className="form-control" type="number" value={this.state.minutes} onChange={this.onChangeMinutes.bind(this)} min={0} max={59}/>
                                                                                </fieldset>
                                                                	</fieldset>
								) : (null) }
								{ (this.state.loaded) ? (
                                                                        <fieldset>
                                                                                <label className="form-label">Duración:</label>
                                                                                <fieldset className="mt-2">
                                                                                        <label className="form-label">Horas:</label>
                                                                                        <input disabled className="form-control" type="number" value={this.state.hours} onChange={this.onChangeHours.bind(this)} min={1}/>
                                                                                </fieldset>
                                                                                <fieldset className="mt-2">
                                                                                        <label className="form-label">Minutos:</label>
                                                                                        <input disabled className="form-control" type="number" value={this.state.minutes} onChange={this.onChangeMinutes.bind(this)} min={0} max={59}/>
                                                                                </fieldset>
                                                                        </fieldset>
                                                                ) : (null) }
								{generator.estimated_by ? (
								<fieldset className="mt-2">
									<label className="form-label">Estimada por {generator.estimated_by}</label>
								</fieldset>
								):(null)}
							</form>
							):(
							<div className="d-grid gap-2">
							<button onClick={()=>this.agregarGeneradorSinHora()} className="btn btn-danger">Agregar equipo electrógeno sin hora</button>
							<button onClick={()=>this.agregarGenerador()} className="btn btn-success">Agregar equipo electrógeno con hora modificable</button>
							<button onClick={()=>this.agregarGeneradorDefecto()} className="btn btn-info">Agregar equipo electrógeno con hora por defecto</button>
							</div>
							)
						}
							{generator ? (
							<fieldset>
								<button onClick={()=>this.eliminarGenerador()} className="btn btn-danger">Eliminar equipo electrógeno</button>
							</fieldset>
							): (null)}
							</div>
						</div>
						</div>
						<form onSubmit={this.handleSubmit} className="mb-3">
                                                        <fieldset>
                                                                 <button className="btn btn-primary mt-3">Guardar cambios</button>
                                                        </fieldset>
                                                </form>
					</div>
				</div>
			</div>) : (null) }
		</section>
		);
	}
}

/*
sin tiempo estimado por el usuario
con tiempo estimado por el usuario
	con duracion por defecto
	sin duracion por defecto
*/
