import React,{  Component } from 'react';
import {  endpoint  } from '../../config';
import Aside from '../../components/Navigator';
import initial_state from './initial_state';

import AppContext from '../../context/app-context';
import NavigatorMobile from '../../components/NavigatorMobile';

function renderList(checked){
	if(checked){
		return(
			<NavigatorMobile/>
		);
	}
	return null;
}

class SignUp extends Component {

	static contextType = AppContext;
  state = { ...initial_state }

  handleChangeEmail(e){ this.setState({ email: e.target.value })  }
  handleChangePassword(e){  this.setState({ password: e.target.value }) }
  handleChangePasswordConfirm(e){ this.setState({ passwordConfirm: e.target.value }) }
  handleChangeRole(e){ this.setState({role: e.target.value })  }

  handleSubmit(e){
    e.preventDefault();
    this.setState({signupErrors:false});
    if(this.state.password!==this.state.passwordConfirm){
      this.setState({signupErrors:'Las contraseñas no coinciden'});
    }else {
        const url = `${endpoint}/users/changepass`;
        const data = {
            id: this.props.match.params.id,
            password:this.state.password.trim(),
            token:localStorage.getItem('token')
        };
        this.setState({buttonEnable:false});
        fetch(url, {
    		  method: 'PUT',
    		  body: JSON.stringify(data),
    		  headers:{'Content-Type': 'application/json',authorization:localStorage.getItem('token')}
    		}).then(res => res.json())
    		.then(response => {
          if(response.success===true){
            this.props.history.push('/accounts');
          }else{
            this.setState({buttonEnable:true,signupErrors:response.error});
          }
    		})
        .catch(err => this.setState({signupErrors:err.message}))
    }
  }

  render(){
    const {signupErrors,buttonEnable} = this.state;
    return(
          <div className="contenedor">
			{renderList(this.context.checked)}
			{(!this.context.checked) ? (
            <div className="cuerpo mb-3">
              <div className="card">
                <div className="card-header">
                  <h2>Cambio de contraseña</h2>
                </div>
                <div className="card-body">
                  <form onSubmit={this.handleSubmit.bind(this)}>
                    <fieldset className="form-group">
                      <label htmlFor="password">Contraseña</label>
                      <input className="form-control" id="password" type="password"  onChange={this.handleChangePassword.bind(this)} required value={this.state.password} />
                    </fieldset>
                    <fieldset className="form-group">
                      <label htmlFor="passwordConfirm">Confirmar contraseña</label>
                      <input className="form-control" id="passwordConfirm" type="password"  onChange={this.handleChangePasswordConfirm.bind(this)} required value={this.state.passwordConfirm}/>
                    </fieldset>
                    {signupErrors ? <div className="alert alert-danger">{this.state.signupErrors}</div>:null}
                    {buttonEnable ? (
                      <button className="btn btn-primary">Cambiar contraseña</button>
                    ):(
                      <button className="btn btn-primary" disabled>Cambiar contraseña</button>
                    )}
                  </form>
                </div>
              </div>
            </div>): (null) }
          </div>
    );
  }

}

export default SignUp;
