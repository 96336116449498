import React,{Component} from 'react';
import Error from '../Error';
import Loader from '../Loader';
import {API_BASE} from '../../config';
import Navigator from '../Navigator';

import AppContext from '../../context/app-context';
import NavigatorMobile from '../../components/NavigatorMobile';

function renderList(checked){
	if(checked){
		return(
			<NavigatorMobile/>
		);
	}
	return null;
}

async function getSensorsAsync(url) 
{
  let response = await fetch(url);
  let data = await response.json()
  return data;
}

export default class Sensors extends Component {

	static contextType = AppContext;
	constructor(){
		super();
		this.state = {
			sensors: null,
			error: null,
			loading: true,
			sensor: {
				name: '',
				channel_id: '',
				read_api_key: '',
				min_temp: '',
				max_temp: ''
			}
		};
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChangeSensorName = this.handleChangeSensorName.bind(this);
		this.handleChangeSensorChannelID = this.handleChangeSensorChannelID.bind(this);
		this.handleChangeSensorReadAPIKey = this.handleChangeSensorReadAPIKey.bind(this);
		this.handleChangeSensorMinTemp = this.handleChangeSensorMinTemp.bind(this);
		this.handleChangeSensorMaxTemp = this.handleChangeSensorMaxTemp.bind(this);
	}


	handleSubmit(e){
		const data = {...this.state.sensor};
		fetch(`${API_BASE}/sensors`, {
  			method: "POST",
  			body: JSON.stringify(data),
  			headers: {"Content-type": "application/json"}
		})
		.then(response => {
			if(response.ok){
				this.setState({loading:true,sensor:{name:'',channel_id:'',read_api_key:'',min_temp:0,max_temp:0}});
				getSensorsAsync(`${API_BASE}/sensors`)
                		.then(data => this.setState({sensors:data,loading:false}))
                		.catch(error => this.setState({error,loading:false}))
			}
		})
		.catch(err => console.log(err));	
		e.preventDefault();
	}

	handleChangeSensorName(e){this.setState({sensor:{...this.state.sensor,name:e.target.value}})};
	handleChangeSensorChannelID(e){this.setState({sensor:{...this.state.sensor,channel_id:e.target.value}})};
	handleChangeSensorReadAPIKey(e){this.setState({sensor:{...this.state.sensor,read_api_key:e.target.value}})};
	handleChangeSensorMinTemp(e){this.setState({sensor:{...this.state.sensor,min_temp:e.target.value}})};
	handleChangeSensorMaxTemp(e){this.setState({sensor:{...this.state.sensor,max_temp:e.target.value}})};

	eliminar({id}){
		fetch(`${API_BASE}/sensors/${id}`, {
                        method: "DELETE",
                        body: JSON.stringify(),
                        headers: {"Content-type": "application/json"}
                })
                .then(response => {
                        if(response.ok){
                                this.setState({loading:true});
                                getSensorsAsync(`${API_BASE}/sensors`)
                                .then(data => this.setState({sensors:data,loading:false}))
                                .catch(error => this.setState({error,loading:false}))
                        }
                })
                .catch(err => console.log(err));
	}

	componentDidMount(){
		getSensorsAsync(`${API_BASE}/sensors`)
		.then(data => this.setState({sensors:data,loading:false}))
		.catch(error => this.setState({error,loading:false}))
	}

	render(){
		const {loading,error,sensors} = this.state;
		if(error) return <Error error={error}/>
		if(loading) return <Loader/>
		return(
		<section className="contenedor">

			{renderList(this.context.checked)}
			{(!this.context.checked) ? (
			<div className="cuerpo mb-3">
				<div className="card">
					<div className="card-header">
						<h2>Sensores</h2>
					</div>
					<div className="card-body">
						<ul className="list-group mb-3">
							{sensors.map((s)=> <li className="list-group-item" key={s._id}>NOMBRE: {s.name} CHANNEL ID: {s.channel_id} READ API KEY: {s.read_api_key} TEMP. MINIMA: {s.min_temp} TEMP. MAXIMA: {s.max_temp}<button onClick={()=>this.eliminar({id:s._id})} className="btn btn-danger" style={{"marginLeft":"1em"}}>Eliminar</button></li>)}
						</ul>
					<form onSubmit={this.handleSubmit}>
						<fieldset className="mb-3">
							<label className="form-label" htmlFor="nameInputSensor">Nombre del sensor:</label>
							<input id="nameInputSensor" className="form-control" value={this.state.sensor.name} onChange={this.handleChangeSensorName}/>
						</fieldset>
						<fieldset className="mb-3">
                                                        <label className="form-label" htmlFor="channelIDInputSensor">Channel ID:</label>
                                                        <input id="channelIDInputSensor" className="form-control" value={this.state.sensor.channel_id} onChange={this.handleChangeSensorChannelID}/>
                                                </fieldset>
						<fieldset className="mb-3">
                                                        <label className="form-label" htmlFor="readAPIKeyInputSensor">Read API key:</label>
                                                        <input id="readAPIKeyInputSensor" className="form-control" value={this.state.sensor.read_api_key} onChange={this.handleChangeSensorReadAPIKey}/>
                                                </fieldset>
						<fieldset className="mb-3">
                                                        <label className="form-label" htmlFor="minTempInputSensor">Temperatura mínima:</label>
                                                        <input type="number" id="minTempInputSensor" className="form-control" value={this.state.sensor.min_temp} onChange={this.handleChangeSensorMinTemp}/>
                                                </fieldset>
						<fieldset className="mb-3">
                                                        <label className="form-label" htmlFor="maxTempInputSensor">Temperatura máxima:</label>
                                                        <input type="number" id="maxTempInputSensor" className="form-control" value={this.state.sensor.max_temp} onChange={this.handleChangeSensorMaxTemp}/>
                                                </fieldset>
						<button className="btn btn-primary">Añadir sensor</button>
					</form>
					</div>
				</div>
			</div>) : (null) }
		</section>
		);
	}
}
