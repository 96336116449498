import React from 'react';

function Footer(){
	const user=localStorage.getItem('role');
	if(user!==null){
		return(
			<footer className="pie">
				<h5>Madacom SRL</h5>
				<p>Soporte: hltmachinecode@gmail.com</p>
			</footer>
		);
	}else {
		return null;
	}
}

export default Footer;
