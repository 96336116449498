import React,{Component} from 'react';
import Mapa from './Mapa.js';
import Navigator from '../Navigator';
import Map from 'ol/Map';
import {API_BASE} from '../../config';
import Loader from '../loaders/Loader';
import Error from '../errors/Error';


import AppContext from '../../context/app-context';
import NavigatorMobile from '../../components/NavigatorMobile';


function renderList(checked){
	if(checked){
		return(
			<NavigatorMobile/>
		);
	}
	return null;
}

export default class Monitor extends Component{

	static contextType = AppContext;

	constructor(props){
		super();
		this.state = {
			sites: null,
			loading: true
		}
	}

	getSites(){
                fetch(`${API_BASE}/sites/awesome`)
                .then(response => response.json())
                .then(data => {
                        this.setState({sites:data,loading:false});
                })
				.catch(err => this.setState({error:err,loading:false}))
        }

	componentDidMount(){
		this.getSites();
	}

	render(){
		const {loading,error} = this.state;
		if(error) return <Error/>
		if(loading) return <Loader/>
		return(
			<section className="contenedor">
				{renderList(this.context.checked)}
				{(!this.context.checked) ? 
				(
					<div className="cuerpo">
						<Mapa sites={this.state.sites}/>
					</div>
				) : (null)}
			</section>
		);
	}
}
