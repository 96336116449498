import React,{Component,createRef} from 'react';
import {endpoint3,endpointMonitor} from '../config';

import socketIOClient from "socket.io-client";

export default class AlarmAlive extends Component {

    constructor(props){
        super(props);
        this.state = {
            src: `${endpoint3}/alive.mp3`,
            type: 'audio/mp3',
            autoplay: false,
            muted: false, 
            socket: null
        };
        this.alarmaAlive = createRef();
    }

    play(){
        this.alarmaAlive.current.play();
    }

    pause(){
        this.alarmaAlive.current.pause();
    }

    reLoad(){
        if(this.alarmaAlive.current){
            this.alarmaAlive.current.load();
            this.alarmaAlive.current.play();
        }   
    }

    unMuted(){
        if(this.alarmaAlive.current) this.alarmaAlive.current.muted=false;
        //this.setState({muted:false});
        this.setState({socket: socketIOClient(endpointMonitor),muted:false});
    }

    muted(){
        if(this.alarmaAlive.current) this.alarmaAlive.current.muted=true;
        this.state.socket.close();
        this.setState({muted:true,socket:null});
    }

	componentDidMount(){
		this.setState({socket: socketIOClient(endpointMonitor)});
	}

	componentWillUnmount(){
		this.state.socket.close();
	}

   	componentDidUpdate(prevProps,prevState) {
		if(prevState.socket!==this.state.socket){
			this.state.socket.emit("subscribeToAliveSounds",(localStorage.getItem('role')||'user'));
            this.state.socket.on("alive",(data)=>{
				this.reLoad(); 
			})
		}
	}

    render(){
        return(
            <div id="alarmaAlive">
                <audio ref={this.alarmaAlive} src={this.state.src} muted={false} type={this.state.type} autoPlay={this.state.autoplay}/>
            </div>
        );
    }
}
