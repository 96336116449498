import React,{Component} from 'react';
import {API_BASE,endpointMonitor} from '../../config';
import Loader from '../Loader';

import socketIOClient from "socket.io-client";

async function getReportsAsync(url)
{
  let response = await fetch(url);
  let data = await response.json()
  return data;
}

export default class GeneratorCounters extends Component {
	constructor(props){
		super(props);
		this.state = {
			loading: true,
			counters: [],
			socketGeneratorCounters: null
		}
	}

	getTime(seconds){
		let h = 0;
		let m = 0;
		let s = 0;
		let hours = parseInt(seconds)/3600;
		h = hours.toString().split('.')[0];
		m = (seconds - (3600*h)) / 60;
		if(m.toString().split('.').length>1){
			s = parseInt(m.toString().split('.')[1])*6;
		}
		m = m.toString().split('.')[0];
		return `${h} hs. ${m} min. ${s} seg.`;
	}

	componentDidMount(){
		getReportsAsync(`${API_BASE}/reports/generators/counters`)
		.then(data => {
				this.setState({counters:data,loading:false})
		})
		.catch(error => this.setState({error}));
		this.setState({socketGeneratorCounters: socketIOClient(endpointMonitor)});
	}
	
	componentWillUnmount(){
		this.state.socketGeneratorCounters.close();
	}

	componentDidUpdate(prevProps,prevState){
		if ((this.state.socketGeneratorCounters !== prevState.socketGeneratorCounters) && (!prevState.socketGeneratorCounters)) {
            this.state.socketGeneratorCounters.emit("subscribeToGeneratorCounters");
			this.state.socketGeneratorCounters.on("FromGeneratorCounters", data => {
				//console.log('FROM Generator Counters', data);
				this.setState({counters:data});
           })
      	}
	}	
	render(){
		if(this.state.loading){
			return <Loader/>
		}
		return(
			<section className="mb-3">
				<h4 className="mb-3">Contadores {new Date().toISOString().slice(0,10)}</h4>
				<ul className="list-group">
					{this.state.counters.map( (c,i) => 
						(<li key={i} className="list-group-item">

							<div className="d-flex w-100 justify-content-between">
                            	<h5 className="mb-1">{c.split(',')[1]} ({c.split(',')[0]})</h5>
                            	<h5>{this.getTime(c.split(',')[3])}</h5>
                            </div>
                            <p className="mb-1">{c.split(',')[2]}</p>
						</li>) 
					)}
				</ul>
			</section>
		);
	}
}
