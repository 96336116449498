const {API_BASE,endpointNotifications,endpointWorker} = require('./config');
const PUBLIC_VAPID_KEY='BA4nsc6JRodW2KFbG5qxGu9kA6wv56SSk7W_AH5meTuLhxStz9d4XVzOmuyHKi6aM_3L1ya9s4bLmeWtt54Pzr4'
const ENDPOINT_NOTIFICATIONS=`${endpointNotifications}/subscribe`;
const ENDPOINT_GET_SUBSCRIPTION=`${endpointNotifications}/getsubscription`;
const ENDPOINT_WORKER=`${endpointWorker}`;

async function send(token){
	/*
    const register = await navigator.serviceWorker.register('https://alertping.tk/worker.js',{
        scope: '/'
    })
	*/
    const register = await navigator.serviceWorker.register(ENDPOINT_WORKER,{
        scope: '/'
    })
    const subscription = await register.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(PUBLIC_VAPID_KEY)
    })
    return await fetch(ENDPOINT_NOTIFICATIONS,{
        method: 'POST',
        body: JSON.stringify({token,subscription}),
        headers: {
            "Content-Type": "application/json"
        }
    })
}

async function getSubscription(token){
	/*
	const register = await navigator.serviceWorker.register('https://alertping.tk/worker.js',{
		scope: '/'
	})		
	*/

	const register = await navigator.serviceWorker.register(ENDPOINT_WORKER,{
		scope: '/'
	})		
	const subscription = await register.pushManager.subscribe({
		userVisibleOnly: true,
		applicationServerKey: urlBase64ToUint8Array(PUBLIC_VAPID_KEY)
	})
	return await fetch(ENDPOINT_GET_SUBSCRIPTION,{
		method: 'POST',
		body: JSON.stringify({token,subscription}),
		headers: {
			"Content-Type": "application/json"
		}
	})
}

function urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
      .replace(/-/g, '+')
      .replace(/_/g, '/');
   
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
   
    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}

function registerSW(token){
    if('serviceWorker' in navigator){
        send(token).catch(err=>console.log(err))
    }
}

function getSub(token){
	if('serviceWorker' in navigator){
		getSubscription(token)
		.then( (res) => console.log('RTA',res))
		.catch(err => console.log(err))
	}
}

export {send,getSubscription};
