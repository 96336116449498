import React,{useContext} from 'react';
import {useHistory} from 'react-router-dom';
import AppContext from '../context/app-context';


function BtnSignout(){
	const context = useContext(AppContext);
	let history = useHistory();
	let from = {pathname:'/'};
	let chau = () => {
		context.setAuth(false);
		history.push('/');
		localStorage.removeItem('role');
		history.replace(from);
	}
	return(
		<svg onClick={chau.bind(this)} xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="white" className="bi bi-box-arrow-left" viewBox="0 0 16 16">
  			<path fill-rule="evenodd" d="M6 12.5a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v2a.5.5 0 0 1-1 0v-2A1.5 1.5 0 0 1 6.5 2h8A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 5 12.5v-2a.5.5 0 0 1 1 0v2z"/>
  			<path fill-rule="evenodd" d="M.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L1.707 7.5H10.5a.5.5 0 0 1 0 1H1.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3z"/>
		</svg>
	);
}

export default BtnSignout;
