import React,{Component} from 'react';
import {API_BASE} from '../../config';
import Error from '../Error';
import Loader from '../Loader';
import Navigator from '../Navigator';
import Circle from '../figures/Circle';


import AppContext from '../../context/app-context';
import NavigatorMobile from '../../components/NavigatorMobile';

function renderList(checked){
	if(checked){
		return(
			<NavigatorMobile/>
		);
	}
	return null;
}

async function getMonitorSitesAsync(url)
{
  let response = await fetch(url);
  let data = await response.json()
  return data;
}

export default class MonitorWithoutMap extends Component {

	static contextType = AppContext;
	constructor(){
		super();
		this.state = {
			sites: [],
			loading: true,
			error: null
		}
	}

	componentDidMount(){
		getMonitorSitesAsync(`${API_BASE}/sites/awesome`)
		.then(data => {
			this.setState({sites:data,loading:false});
		})
		.catch(error => this.setState({error}))
	}

	renderItems(items){
		if(items&&items.length>0){
			return (
				<div className="card mt-3">
				<div className="card-header">
					Equipos
				</div>
				<ul className="list-group">
					{items.map((i,k) => <li className="list-group-item list-group-flush" key={k}>{i.name} ({i.ip}) {i.type} {this.renderCircle(i.status,i.type)}</li>)}
				</ul>
				</div>
			)
		}else{
			return <ul className="list-group"><li className="list-group-item" key={1}>sin equipos</li></ul>
		}
	}
	renderGenerator(generator){
		if(generator&&generator.ip){
			return(
				<div className="card mt-3">
				<div className="card-header">
					Equipo electrógeno
				</div>
				<div className="card-body">
                               		<h5 className="card-title">{generator.name} ({generator.ip})</h5>
                                	<p className="card-text">{this.renderCircle(generator.status)}</p>
                                </div>
				</div>
			);
		}
	}
	
	renderCircle(status,type){
		if(type==='TESTIGO GENERADOR'&&status==='U'){
			return <Circle color={"green"}/>
		}else if(type==='TESTIGO GENERAOR'&&status==='A'){
			return <Circle color={"red"}/>
		}else if(status==='A'){
			return <Circle color={"green"}/>
		}else if(status==='U'){
			return <Circle color={"red"}/>
		}else return null;
	}

	render(){
		const {error,sites,loading} = this.state;
		if (error) return <Error error={error}/>
		if (loading) return <Loader/>
		return(
		<section className="contenedor">
			{renderList(this.context.checked)}
			{(!this.context.checked) ? (
			<div className="cuerpo mb-3">
				<div className="card">
					<div className="card-header">
						<h2>Monitor sin mapa</h2>
					</div>
					<div className="card-body">
						<ul className="list-group">
							{sites.map((s,k) => (
								<li className="list-group-item" key={k}>
									<div className="btn btn-outline-dark">{s.name}</div> {this.renderCircle(s.status)}
								{this.renderItems(s.items)}
								{this.renderGenerator(s.generator)}
								</li>)
							)}
						</ul>
					</div>
				</div>
			</div>) : (null) }
		</section>
		);
	}
}
