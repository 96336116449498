/*
const protocol = 'http';
const domain = 'localhost';
export const API_BASE = `${protocol}://${domain}:4000`;
export const API_UPLOADS = `${protocol}://${domain}:4021`;
export const endpoint3 = `${protocol}://${domain}:3000`;
export const endpointSound = `${protocol}://${domain}:4001`;
export const endpoint = `${protocol}://${domain}:4000`;
export const endpointNotifications = `${protocol}://${domain}:4002`;
export const endpointMonitor = `${protocol}://${domain}:4003`;
export const endpointCommands = `${protocol}://${domain}:4020`;
export const endpointWorker = `${protocol}://${domain}:3000/worker.js`;
*/
const protocol = 'https';
const domain = 'alertping.com.ar';
export const API_BASE = `${protocol}://api.${domain}`;
export const API_UPLOADS = `${protocol}://uploads.${domain}`;
export const endpoint3 = `${protocol}://${domain}`;
export const endpointSound = `${protocol}://sounds.${domain}`;
export const endpoint = `${protocol}://api.${domain}`;
export const endpointNotifications = `${protocol}://notifications.${domain}`;
export const endpointMonitor = `${protocol}://monitor.${domain}`;
export const endpointCommands = `${protocol}://commands.${domain}`;
export const endpointWorker = `${protocol}://${domain}/worker.js`;
