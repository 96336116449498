import React from 'react';

function Loader(){
	return(
		<section className="contenedor">
			<div className="cuerpo">
				<h2>cargando...</h2>
			</div>
		</section>
	);
}

export default Loader;
