import React,{Component} from 'react';
import Error from '../Error';
import {endpointCommands} from '../../config';
import Navigator from '../Navigator';

import AppContext from '../../context/app-context';
import NavigatorMobile from '../../components/NavigatorMobile';

function renderList(checked){
	if(checked){
		return(
			<NavigatorMobile/>
		);
	}
	return null;
}

export default class Comandos extends Component {

	static contextType = AppContext;
	constructor(){
		super();
		this.state = {
			comando: '',
			error: null,
			loading: false,
			data: []
		};
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}


	handleSubmit(e){
		this.setState({loading:true});
		fetch(`${endpointCommands}/pings/${this.state.ip}`)
		.then( response => response.json())
		.then(response => {
			this.setState({loading:false,data:response});
		})
		.catch(err => {console.log(err);this.setState({loading:false})});	
		e.preventDefault();
	}

	handleChange(e){this.setState({ip:e.target.value})}

	render(){
		const {loading,error,ip} = this.state;
		if(error) return <Error error={error}/>
		return(
		<section className="contenedor">

			{renderList(this.context.checked)}
			{(!this.context.checked) ? (
			<div className="cuerpo mb-3">
				<div className="card">
					<div className="card-header">
						<h2>COMANDOS</h2>
					</div>
					<section className="card-body">
					<form onSubmit={this.handleSubmit}>
						<fieldset className="mb-3">
							<label className="form-label" htmlFor="ipInput">IP:</label>
							<input id="ipInput" className="form-control" value={this.state.ip} onChange={this.handleChange} required/>
						</fieldset>
						{ (!this.state.loading) ? (
						<button className="btn btn-primary">Consultar estado</button>
						) : (
						<button className="btn btn-primary" disabled>Consultar estado</button>
						) }
					</form>
					{ (this.state.loading) ?  (
						<section style={{"marginTop":"3em","marginBottom":"3em","display":"flex","justifyContent":"center","alignItems":"center","width":"100%","minHeight":"40vh"}}>
						<div className="spinner-border text-primary">
						</div>
						</section>
					) : (null)}
					{ (this.state.data.length>0) ? (
						<section style={{"marginTop":"3em","marginBottom":"3em","display":"flex","justifyContent":"center","alignItems":"center","width":"100%","minHeight":"40vh","flexWrap":"wrap"}}>
						{this.state.data.map((d,i) => <p key={i} style={{"width":"100%","textAlign":"center"}}>{d}</p>)}
						</section>
					) : (null) }
					</section>
				</div>
			</div>) : (null) }
		</section>
		);
	}
}
