import React,{Component} from 'react';

import AppContext from '../../context/app-context';
import NavigatorMobile from '../../components/NavigatorMobile';

function renderList(checked){
	if(checked){
		return(
			<NavigatorMobile/>
		);
	}
	return null;
}

export default class Error extends Component{

	static contextType = AppContext;

	constructor(props){
		super();
	}
	
	render(){
		return(
			<section className="contenedor">
				{renderList(this.context.checked)}
				{(!this.context.checked) ? 
				(
					<div className="cuerpo">
						<h2>Ha ocurrido un error</h2>
					</div>
				) : (null)}
			</section>
		);
	}
}
