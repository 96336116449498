import React,{ Component } from 'react';
import { endpoint } from '../../config';
import Loading from '../../components/Loader';
import Aside from '../../components/Navigator';
import { LineChart, Line, YAxis, XAxis, CartesianGrid, Tooltip, Legend } from 'recharts';


import AppContext from '../../context/app-context';
import NavigatorMobile from '../../components/NavigatorMobile';

function renderList(checked){
	if(checked){
		return(
			<NavigatorMobile/>
		);
	}
	return null;
}

export default class SensorsStatistics extends Component {

	static contextType = AppContext;
	constructor(){
		super();
		this.state = {
			sensors: null,
			sensor:"",
			isLoaded: false,
			datos: [],
			lastDate: '',
			loading: true,
			valores: [],
		};
		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(e) {    this.setState({sensor: e.target.value});  }

  	getSensors(){
    		fetch(`${endpoint}/sensors`,{headers:{
      			'Content-Type': 'application/json','authorization': `${localStorage.getItem('token')}`
    		}})
      		.then(res => res.json())
      		.then(
        		(result) => {
					console.log('rrrrrr',result);
					this.setState({sensors:result,sensor:0,isLoaded:true});
				}
      		)
		.then(()=>{
			this.getStatistics()
		})
      		.catch(err => console.log(err))
  	}


	getStatistics(){
		let promises = [];
		let sensors = this.state.sensors;
		for(let i=0;i<sensors.length;i++){
			promises.push(
    			fetch(`https://api.thingspeak.com/channels/${sensors[i].channel_id}/fields/1-2-3.json?api_key=${sensors[i].read_api_key}&results=${60*6}&timezone=America%2FArgentina%2FBuenos_Aires`)
				.then( res => res.json())
			)
		}
		Promise.all(promises).then(values => {
			let names = [];
			let datos = [];
			let valores = values;
			for(let j=0;j<values.length;j++){
				for(let k=0;k<this.state.sensors.length;k++){
						if(this.state.sensors[k].channel_id===values[j].channel.id.toString()){
							names.push(this.state.sensors[k].name);
						}
				}
			}
			for(let i=0;i<360;i++){
				let dato = {};
				for(let j=0;j<names.length;j++){
					dato[`${names[j]}`] = 0.0;
					dato['created_at'] = "";
				}
				datos.push(dato);
			}
			for(let j=0;j<values.length;j++){
				for(let k=0;k<values[j].feeds.length;k++){
					datos[k]['created_at'] = values[j].feeds[k].created_at.substring(11,16);
					datos[k][`${names[j]}`] = parseFloat(values[j].feeds[k].field1).toFixed(2);
				}
			}
			let colores = [];
			for(let i=0;i<40;i++){
				colores.push('#'+Math.floor(Math.random()*16777215).toString(16));
			}
			this.setState({datos:datos,colores,names,loading:false,valores});
		});
  	}

  	componentDidMount(){
    		this.getSensors();
  	}

	componentDidUpdate(prevProps,prevState){
                if(prevState.sensor!==this.state.sensor){
                        this.setState({loading:true});
                        this.getStatistics(this.state.sensor);
                }
        }

	renderLinesTemp(){
		return this.state.sensors.map( (s,i) => <Line type="monotone" dataKey={`${this.state.names[i]}`} stroke={this.state.colores[i]} />)
	}

  render(){
    const {isLoaded} = this.state;
	if(!isLoaded) return <Loading/>;
      return(
          <main className="contenedor">

			{renderList(this.context.checked)}
			{(!this.context.checked) ? (
            <div className="cuerpo mb-3">
              <div className="card">
                <div className="card-header">
                  <h2>Estadísticas de sensores</h2>
                </div>
                <div className="card-body">
			<h4>SENSORES</h4>
			{this.state.loading ? (
			<div className="spinner-border text-success" role="status">
			</div>
			) : (
				<section style={{overflow:"auto"}}>
				<LineChart width={1024} height={480} data={this.state.datos}
  					margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
  					<CartesianGrid strokeDasharray="3 3" />
  					<YAxis />
					<XAxis dataKey="created_at" stroke="#8884d8" />
  					<Tooltip />
  					<Legend />
					{this.renderLinesTemp()}
				</LineChart>
				</section>
			) }
			</div>

			<div className="mt-3 card">
				<div className="card-header">
					<h3>Últimas temperaturas reportadas</h3>
				</div>
				<div className="card-body">
				<ul className="list-group">
				{this.state.valores.map( (v,i) => (
					<li className="list-group-item">
						<div class="d-flex w-100 justify-content-between">
      						<h5 class="mb-1">{this.state.sensors[i].name}</h5>
      						<small>{v.feeds[v.feeds.length-1].created_at.substring(0,10)} {v.feeds[v.feeds.length-1].created_at.substring(11,16)}</small>
    					</div>
    					<p class="mb-1">{v.feeds[v.feeds.length-1].field1}</p>
					</li>
					)
				)}
				</ul>
				</div>
			</div>

			</div>
            </div>) : (null) }
          </main>
      );
  }
}
