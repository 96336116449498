import React,{Component} from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import AppContext,{initial_state} from './context/app-context';
//import SignIn from './components/sign/SignIn';
import Header from './components/Header';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';
import Monitor from './components/monitor/Monitor';
import Sites from './components/sites/Sites';
import NewSite from './components/sites/NewSite';
import EditSite from './components/sites/EditSite';
import Location from './components/sites/Location';
import NoMatch from './components/NoMatch';
//import Users from './components/users/Users';
//import SignUp from './components/sign/SignUp';
import Kits from './components/kits/Kits';
import Sensors from './components/sensors/Sensors';
import Reports from './components/reports/Reports';
import ReportsGenerator from './components/reports/ReportsGenerator';
import ReportsSensors from './components/reports/ReportsSensors';
import MonitorWithoutMap from './components/monitor/MonitorWithoutMap';
import Statistics from './components/statistics/Statistics';
import SensorsStatistics from './components/statistics/SensorsStatistics';
import Comandos from './components/comandos/Comandos';
//accounts
import SignIn from './components/sign/SignIn';
import SignUp from './components/sign/SignUp';
import Accounts from './components/sign/Accounts';
//changepass
import ChangePass from './components/sign/ChangePass';

import UploadSound from './components/uploads/UploadSound';

class Root extends Component {
	state = {
		...initial_state,
		checked: false,
		setCoordenates: (coordenates) => this.setState({coordenates}),
		setAuth: (auth) => this.setState({auth}),
		setChecked: (checked) => this.setState({checked})
	}
	render(){
		return(
		<AppContext.Provider value={this.state}>
			<BrowserRouter>
				<ScrollToTop>
					{this.state.auth ? (
						<React.Fragment>
							<Header/>
							<Switch>
								<Route exact path="/" render={()=>(
								this.state.auth ? (<Monitor/>):(<SignIn/>)
								)}/>		
								<Route path="/sites" component={Sites}/>
								<Route path="/newsite" component={NewSite}/>
								<Route path="/editsite/:id" component={EditSite}/>
								<Route path="/location/:id" component={Location}/>
								<Route path="/signup" component={SignUp}/>
								<Route path="/kits" component={Kits}/>
								<Route path="/reports" component={Reports}/>
								<Route path="/reportsgenerator" component={ReportsGenerator}/>
								<Route path="/reportssensors" component={ReportsSensors}/>
								<Route path="/monitorwithoutmap" component={MonitorWithoutMap}/>

								<Route path="/accounts" component={Accounts}/>
								<Route path="/changepass/:id" component={ChangePass}/>
								<Route path="/statistics" component={Statistics}/>
								<Route path="/sensorsstatistics" component={SensorsStatistics}/>
								<Route path="/sensors" component={Sensors}/>
								<Route path="/comandos" component={Comandos}/>
								<Route path="/uploads" component={UploadSound}/>
							</Switch>
						</React.Fragment>
					):(
						<React.Fragment>
							<Switch>
								<Route exact path="/" render={()=>(
								this.state.auth ? (<Monitor/>):(<SignIn/>)	
								)}/>
								<Route component={NoMatch}/>
							</Switch>
						</React.Fragment>
					)}
				</ScrollToTop>
				<Footer/>
			</BrowserRouter>
		</AppContext.Provider>
		);
	}
}

export default Root;
