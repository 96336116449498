import React,{Component,createRef} from 'react';
import Map from 'ol/Map';
import View from 'ol/View';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer';
import {OSM} from 'ol/source';
import {fromLonLat,toLonLat} from 'ol/proj';
import {Style,Circle as CircleStyle, Fill, Stroke} from 'ol/style';
import Point from 'ol/geom/Point';
import Feature from 'ol/Feature';
import VectorSource from 'ol/source/Vector';
import {Draw, Modify, Snap} from 'ol/interaction';
import {API_BASE} from '../../config';
import Navigator from '../Navigator';

import AppContext from '../../context/app-context';
import NavigatorMobile from '../../components/NavigatorMobile';

function renderList(checked){
	if(checked){
		return(
			<NavigatorMobile/>
		);
	}
	return null;
}

export default class Location extends Component {
	static contextType = AppContext;
        constructor(){
                super();
                this.myRef = createRef();
		this.state = {
			mapa: null,
			coordenates: null
		};
		this.handleChange = this.handleChange.bind(this);
        }
	handleChange(e){
		let data = {
			id: this.props.match.params.id,
			coordinates: {
				latitude: this.context.coordenates[1],
				longitude: this.context.coordenates[0]
			}
		}
		fetch(`${API_BASE}/sites/location`, {
  			method: "PUT",
  			body: JSON.stringify(data),
  			headers: {"Content-type": "application/json"}
		})
		.then(response => {
			if(response.ok){
				this.props.history.push('/');
			}
		}) 
		.catch(err => console.log(err));
	}
        componentDidMount(){
		let X = -57.5177029;
		let Y = -35.0805371;
		/*
		let rome = new Feature({
  			geometry: new Point(fromLonLat([X, Y])),
		});
		*/
		let rome = new Feature({});
		rome.setStyle(
  			new Style({
				fill: new Fill({
      					color: 'rgba(255, 255, 255, 0.2)',
    				}),
    				stroke: new Stroke({
      					color: '#ffcc33',
      					width: 2,
    				}),
    				image: new CircleStyle({
      					radius: 7,
      					fill: new Fill({
        					color: '#ffcc33',
      					}),
    				})
  			})
		);
		let vectorSource = new VectorSource({
  			features: [rome],
		});
		let vectorLayer = new VectorLayer({
  			source: vectorSource,
		});
		let raster = new TileLayer({
  			source: new OSM(),
		});
		let coordenadas;
		let mapa = this.myRef.current;
		let value = this.context;
                mapa = new Map({
                        target: this.myRef.current,
                        layers: [raster,vectorLayer],
                        view: new View({
                                center: fromLonLat([X,Y]),
                                zoom: 10
                        })
                });
		let modify = new Modify({source: vectorSource});
                mapa.addInteraction(modify);
		var draw, snap;
                function addInteractions() {
                        draw = new Draw({
                                source: vectorSource,
                                type: "Point",
                        });
                        mapa.addInteraction(draw);
                        draw.on('drawend', function(e) {
                                let feature = e.feature;
                                coordenadas = toLonLat(feature.getGeometry().flatCoordinates);
                                value.setCoordenates(coordenadas);
                                mapa.removeInteraction(draw);
                        },this);
                        snap = new Snap({source: vectorSource});
                        mapa.addInteraction(snap);
                }
                addInteractions();
		modify.on('modifyend',function(e){
			var features = e.features.getArray();
			coordenadas = toLonLat(features[0].getGeometry().flatCoordinates);
			value.setCoordenates(coordenadas);

		});
		fetch(`${API_BASE}/sites/${this.props.match.params.id}`)
    		.then(response => response.json()) 
    		.then(json => {
			if(json.coordinates.latitude!==0&&json.coordinates.longitude!==0){
				rome.setGeometry(new Point(fromLonLat([json.coordinates.longitude,json.coordinates.latitude])));
				mapa.removeInteraction(draw);
			} 
		}) 
    		.catch(err => console.log('Request Failed', err)); 
        }
	
        render(){
                return(
		<section className="contenedor">

			{renderList(this.context.checked)}
			{(!this.context.checked) ? (
			<div className="cuerpo mb-3">
				<div className="card">
					<div className="card-header">
						<h2>Ubicar</h2>
					</div>
					<div className="card-body">
						<h3>Haga click o seleccione con el dedo donde se encuentra el sitio</h3>
                        			<div ref={this.myRef} style={{"width":"100%","height":"100vh",marginTop:"2em"}} className="mb-3"/>
						<button className="btn btn-primary" onClick={this.handleChange}>Guardar cambios</button>
					</div>
				</div>
			</div>) : (null) }
		</section>
                );
        }
}

