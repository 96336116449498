import React from 'react';

function MsgError(props){
	return(
		<section>
			<p>{props.message}</p>
		</section>
	);
}

export default MsgError;
