import React,{Component} from 'react';
import {endpointNotifications} from '../config';
import {send,getSubscription} from '../client';

export default class BtnPush extends Component {
	state = {
		isSubscribed: false,
		loading: true,
		error: null
	}
	getSubscript(){
		getSubscription(localStorage.getItem('token'))
        .then(res => {
        	if(res.status===200){
        		this.setState({isSubscribed: true,loading:false});
        	}else {
				this.setState({isSubscribed: false,loading:false});
			}
        })
       .catch( err => {
			this.setState({loading:false,error:"X"});
			console.log(err)
		});
	}
	subscribe(){
		this.setState({loading:true});
		send(localStorage.getItem('token'))
		.then( res => {
			if(res.status===200){
				this.setState({isSubscribed: true});
			}else{
				this.setState({isSubscribed: false});
			}
		})
		.then( () => this.getSubscript())
		.catch( err => console.log(err));
	}
	unsuscribe(){
		this.setState({loading:true});
		let rta = window.confirm("¿En realidad desea anular su suscripción?");
		if(rta){
			const token = localStorage.getItem('token');
			fetch(`${endpointNotifications}/unsuscribe`, {
  				method: "POST",
  				body: JSON.stringify({token}),
  				headers: {"Content-type": "application/json"}
			})
			.then(response => {
				if(response.ok){
					this.setState({isSuscribed:false});
				}else {
					this.setState({isSuscribed:true});
				}
			})
			.then( () => this.getSubscript())
			.catch(err => console.log(err));	
		}
	}

	componentDidMount(){
		this.getSubscript();
	}
	render(){
		if(this.state.error){
			return(
				<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="red" className="bi bi-bell-slash" viewBox="0 0 16 16">
  					<path d="M5.164 14H15c-.299-.199-.557-.553-.78-1-.9-1.8-1.22-5.12-1.22-6 0-.264-.02-.523-.06-.776l-.938.938c.02.708.157 2.154.457 3.58.161.767.377 1.566.663 2.258H6.164l-1 1zm5.581-9.91a3.986 3.986 0 0 0-1.948-1.01L8 2.917l-.797.161A4.002 4.002 0 0 0 4 7c0 .628-.134 2.197-.459 3.742-.05.238-.105.479-.166.718l-1.653 1.653c.02-.037.04-.074.059-.113C2.679 11.2 3 7.88 3 7c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0c.942.19 1.788.645 2.457 1.284l-.707.707zM10 15a2 2 0 1 1-4 0h4zm-9.375.625a.53.53 0 0 0 .75.75l14.75-14.75a.53.53 0 0 0-.75-.75L.625 15.625z"/>
				</svg>
			);
		}else if(this.state.loading){
			return(
				<div className="spinner-border"></div>
			);
		}else if(!this.state.isSubscribed){
			return(
			<svg xmlns="http://www.w3.org/2000/svg" onClick={()=>this.subscribe()} width="32" height="32" fill="white" className="bi bi-bell" viewBox="0 0 16 16">
  				<path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z"/>
			</svg>
			);
		}
		return(
			<svg xmlns="http://www.w3.org/2000/svg" onClick={()=>this.unsuscribe()} width="32" height="32" fill="white" className="bi bi-bell-fill" viewBox="0 0 16 16">
  				<path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zm.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z"/>
			</svg>
		);
	}
}

/*
	render(){
		if(this.state.error){
			return(
				<button className="btn btn-danger" style={{"marginRight":"1em"}}>X</button>
			);
		}else if(this.state.loading){
			return(
				<button className="btn btn-outline-success" style={{"marginRight":"1em"}}>
					<div className="spinner-border"></div>
				</button>
			);
		}else if(!this.state.isSubscribed){
			return(
			<button className="btn btn-success" onClick={()=>this.subscribe()} style={{"marginRight": "1em"}}>Suscribirse</button>
			);
		}
		return(
			<button className="btn btn-outline-success" onClick={()=>this.unsuscribe()} style={{"marginRight":"1em"}}>Suscrito</button>
		);
	}
*/
