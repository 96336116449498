import React from 'react';
import Rect from './Rect';

export default function Bar(props){
	return(
		<section style={{overflow:"auto"}}>
		<svg width="1000" height="400">
			{props.data.map( (d,i) => <Rect key={i} color={"#198754"} data={d} indice={i}/>)}
                </svg>
		</section>
	);
}
