import React from 'react';

function Error(props){
	return(
		<section>
			<h2>Error {props.error}</h2>
		</section>
	);
}

export default Error;
