import React,{Component} from 'react';
import Error from '../Error';
import Loader from '../Loader';
import {API_BASE} from '../../config';
import Navigator from '../Navigator';


import AppContext from '../../context/app-context';
import NavigatorMobile from '../../components/NavigatorMobile';

function renderList(checked){
	if(checked){
		return(
			<NavigatorMobile/>
		);
	}
	return null;
}

async function getKitsAsync(url) 
{
  let response = await fetch(url);
  let data = await response.json()
  return data;
}

export default class Kits extends Component {

	static contextType = AppContext;
	constructor(){
		super();
		this.state = {
			kits: null,
			error: null,
			loading: true,
			name: ''
		};
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}


	handleSubmit(e){
		const data = {
			name: this.state.kit
		}
		fetch(`${API_BASE}/kits`, {
  			method: "POST",
  			body: JSON.stringify(data),
  			headers: {"Content-type": "application/json"}
		})
		.then(response => {
			if(response.ok){
				this.setState({loading:true});
				getKitsAsync(`${API_BASE}/kits`)
                		.then(data => this.setState({kits:data,loading:false}))
                		.catch(error => this.setState({error,loading:false}))
			}
		})
		.catch(err => console.log(err));	
		e.preventDefault();
	}

	handleChange(e){this.setState({kit:e.target.value})}

	eliminar({id}){
		fetch(`${API_BASE}/kits/${id}`, {
                        method: "DELETE",
                        body: JSON.stringify(),
                        headers: {"Content-type": "application/json"}
                })
                .then(response => {
                        if(response.ok){
                                this.setState({loading:true});
                                getKitsAsync(`${API_BASE}/kits`)
                                .then(data => this.setState({kits:data,loading:false}))
                                .catch(error => this.setState({error,loading:false}))
                        }
                })
                .catch(err => console.log(err));
	}

	componentDidMount(){
		getKitsAsync(`${API_BASE}/kits`)
		.then(data => this.setState({kits:data,loading:false}))
		.catch(error => this.setState({error,loading:false}))
	}

	render(){
		const {loading,error,kits} = this.state;
		if(error) return <Error error={error}/>
		if(loading) return <Loader/>
		return(
		<section className="contenedor">

			{renderList(this.context.checked)}
			{(!this.context.checked) ? (
			<div className="cuerpo mb-3">
				<div className="card">
					<div className="card-header">
						<h2>Equipos</h2>
					</div>
					<div className="card-body">
						<ul className="list-group mb-3">
							{kits.map((k)=> <li className="list-group-item" key={k._id}>{k.name}<button onClick={()=>this.eliminar({id:k._id})} className="btn btn-danger" style={{"marginLeft":"1em"}}>Eliminar</button></li>)}
						</ul>
					<form onSubmit={this.handleSubmit}>
						<fieldset className="mb-3">
							<label className="form-label" htmlFor="nameInputKit">Tipo de equipo:</label>
							<input id="nameInputKit" className="form-control" value={this.state.kit} onChange={this.handleChange} required/>
						</fieldset>
						<button className="btn btn-primary">Crear</button>
					</form>
					</div>
				</div>
			</div>) : (null) }
		</section>
		);
	}
}
