import React,{ Component } from 'react';
import { endpoint } from '../../config';
import {fromJS} from 'immutable';
import Loading from '../../components/Loader';
import Aside from '../../components/Navigator';

import ModalSuccess from '../../components/ModalSuccess';

import AppContext from '../../context/app-context';
import NavigatorMobile from '../../components/NavigatorMobile';

function renderList(checked){
	if(checked){
		return(
			<NavigatorMobile/>
		);
	}
	return null;
}

class Accounts extends Component {

	static contextType = AppContext;

  state = {
    accounts: fromJS([]),
    error: null,
    isLoaded: false,
    buttonEnable: true,
    accountsError: false,
    roles: ["user","admin"],
    show: false
  };

		
	hideModal = () => {
		this.setState({ show: false });
	};

  newAccount(){ this.props.history.push('/signup') }

  handleChangeRole({id,role,i},e){
    this.setState({accounts:this.state.accounts.setIn([i,'role'],role)});
  }

  deleteAccount({i,id}){
    const email = localStorage.getItem('email');
    if(this.state.accounts.getIn([i,'email'])!==email){
      let result = window.confirm('¿Desea borrar la cuenta?')
      if (result) {
        this.setState({buttonEnable:false,accountsError:false});
        fetch(`${endpoint}/users`, {
          method: 'DELETE',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({id,token:localStorage.getItem('token')})
        })
        .then(res => res.json())
        .then(res => {
          this.setState({buttonEnable:true,accounts:this.state.accounts.delete(i)});
        })
        .catch(err => console.log(err))
      }
    }else{
      this.setState({accountsError:'La cuenta esta en uso, para borrarla debes ingresar a otra cuenta'})
    }
  }

  updateRole({id,role,i},e){
    this.setState({buttonEnable:false,accountsError:false})
    fetch(`${endpoint}/users`, {
	    method: 'PUT',
	    headers: {'Content-Type': 'application/json'},
	    body: JSON.stringify({
	      id,role,token:localStorage.getItem('token')
	    })
	  })
	  .then(res => res.json())
	  .then(res => {
      if (res.success){
        this.setState({buttonEnable:true,show:true});
      }
	  })
    .catch(err => console.log(err))
  }

  changePass({id},e){
    //this.setState({buttonEnable:false,accountsError:false});
    this.props.history.push(`/changepass/${id}`);
  }

  getAccounts(){
    fetch(`${endpoint}/users`,{headers:{
      'Content-Type': 'application/json','authorization': `${localStorage.getItem('token')}`
    }})
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({accounts: fromJS(result), isLoaded: true});
      })
      .catch(err => console.log(err))
  }

  componentDidMount(){
    this.getAccounts();
  }

  render(){
    const {isLoaded,buttonEnable,accountsError} = this.state;
    if (!isLoaded){
      return(<Loading/>);
    }else {
      return(
          <main className="contenedor">

			{renderList(this.context.checked)}
			{(!this.context.checked) ? (
            <div className="cuerpo mb-3">

              <div className="card">
                <div className="card-header">
                  <h2>Lista de cuentas</h2>
                </div>

                <ModalSuccess title={"Estado de la operación"} description={"Se ha actualizado el rol exitosamente"} show={this.state.show} handleClose={this.hideModal}/>
                <div className="card-body">

                {(this.state.accounts.size>0) ? (
                  <ul className="list-group">
                    {this.state.accounts.map((v,i) => {
                      return (
                          <li className="list-group-item" key={i}>
                            <div className="form-row">
                              <div className="col">
                                <label>Usuario: </label>
                                <p>{v.get('email')}</p>
                              </div>
                              <div className="col">
                              <label>Rol: </label>
                              <select className="form-control mb-2" value={v.get('role')} onChange={(e)=>this.handleChangeRole({i,id:v.get('_id'),role:e.target.value},e)}>
                                  {this.state.roles.map((r,j) => {
                                  return (<option value={r} key={j}>{r}</option>)
                                })}
                              </select>
                            </div>
                            </div>
				
                           
                            {buttonEnable ? (
                              <React.Fragment>
                                <button className="btn btn-primary" style={{"marginRight": "1em"}}
                                  onClick={(e)=>this.updateRole({i,id:v.get('_id'),role:v.get('role')},e)}>
                                  Cambiar rol
                                </button>
                                <button className="btn btn-warning" style={{"marginRight": "1em"}}
                                  onClick={(e)=>this.changePass({id:v.get('_id')},e)}>
                                  Cambiar contraseña
                                </button>
                                <button className="btn btn-danger" onClick={(e)=>this.deleteAccount({i,id:v.get('_id')},e)}>
                                  Eliminar
                                </button>
                              </React.Fragment>
                            ):(
                              <React.Fragment>
                                <button className="btn btn-primary mr-2" disabled>Cambiar rol</button>
                                <button className="btn btn-warning mr-2" disabled>Cambiar contraseña</button>
                                <button className="btn btn-danger" disabled>Eliminar</button>
                              </React.Fragment>
                            )}
							
                          </li>
                        )
                    })}
                  </ul>
                ):(
                  <ul className="list-group">
                    <li className="d-flex justify-content-between align-items-center list-group-item">no hay cuentas</li>
                  </ul>
                )}
                </div>
                <div className="card-footer">
                  {buttonEnable ? (
                    <button className="btn btn-primary" onClick={()=>this.newAccount()}>Nueva cuenta</button>
                  ):(
                    <button className="btn btn-primary" disabled>Nueva cuenta</button>
                  )}
                  {accountsError ? (
                    <div className="alert alert-danger mt-2" role="alert">{accountsError}</div>
                    ):(
                    null
                    )}
                </div>

              </div>
            </div>) : (null) }
          </main>
      );
    }

  }
}

export default Accounts;
