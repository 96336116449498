import React,{Component} from 'react';
import {API_BASE} from '../../config';
import Loader from '../Loader';
import Error from '../Error';
import Navigator from '../Navigator';
import Circle from '../figures/Circle';


import AppContext from '../../context/app-context';
import NavigatorMobile from '../../components/NavigatorMobile';

import GeneratorCounters from './GeneratorCounters';

function renderList(checked){
	if(checked){
		return(
			<NavigatorMobile/>
		);
	}
	return null;
}


async function getReportsAsync(url)
{
  let response = await fetch(url);
  let data = await response.json()
  return data;
}


export default class Reports extends Component {

	static contextType = AppContext;
	constructor(){
		super();
		this.state = {
			date: '',
			loading: true,
			error: null,
			reports: [],
			reports_aux: [],
			reports_filter: '',
			reports_filtered: false,
			lasts: [],
			lasts_aux: [],
			lasts_filter: '',
			lasts_filtered: false,	
			buttonEnabled: true
		}
	}
	renderStatus(type){
		if(type==="A") return <small><Circle color={"green"}/></small>;
		if(type==="U") return <small><Circle color={"red"}/></small>;
		return null;
	}
	componentDidMount(){
		this.setState({date: new Date().toISOString().slice(0,10)});
		getReportsAsync(`${API_BASE}/reports/generators/last`)
		.then(data => {
			if(!data.code){
				this.setState({lasts:data,loading:false})
			}else{
				this.setState({lasts:[],loading:false});
			}
		})
		.catch(error => this.setState({error}));
	}

	seeReport(){
		this.setState({buttonEnabled:false});
		getReportsAsync(`${API_BASE}/reports/generators/date/${this.state.date}`)
                .then(data => {
                	if(!data.code){
                     		this.setState({reports:data,loading:false,buttonEnabled:true})
                   	}else{
                     		this.setState({reports:[],loading:false,buttonEnabled:true});
                   	}
                })
        	.catch(error => this.setState({error,buttonEnabled:true}));
	}

	handleChangeDate(e){
		this.setState({date:e.target.value});
	}

	handleChangeLastsFilter(e){
		this.setState({lasts_filter: e.target.value});
	}
	
	handleChangeReportsFilter(e){
		this.setState({reports_filter: e.target.value});
	}

	reportsFilter(){
		let word = `,${this.state.reports_filter},`;
		function filtrar(cadena){
			return cadena.includes(word);
		}
		let aux = this.state.reports.filter(filtrar);
		this.setState({reports_aux: this.state.reports,reports:aux,reports_filtered:true,reports_filter:''});
	}


	lastsFilter(){
		let word = `,${this.state.lasts_filter},`;
		function filtrar(cadena){
			return cadena.includes(word);
		}
		let aux = this.state.lasts.filter(filtrar);
		this.setState({lasts_aux: this.state.lasts,lasts:aux,lasts_filtered:true,lasts_filter:''});
	}

	removeReportsFilter(){
		this.setState({reports:this.state.reports_aux,reports_filtered:false});
	}


	removeLastsFilter(){
		this.setState({lasts:this.state.lasts_aux,lasts_filtered:false});
	}


	renderDate(fecha){
		if(fecha){
			return <React.Fragment>{fecha.split('T')[0]} {fecha.split('T')[1]}</React.Fragment>
		}
		return null;
	}

	render(){
		const {error,loading,reports,lasts,buttonEnabled} = this.state;
		if (error) return <Error error={error}/>
		if (loading) return <Loader/>
		return(
		<section className="contenedor">

			{renderList(this.context.checked)}
			{(!this.context.checked) ? (
			<div className="cuerpo mb-3">
				<div className="card">
					<div className="card-header">
						<h2>Reportes</h2>
					</div>
					<div className="card-body">
						<GeneratorCounters/>
						<div className="mb-3">
							<h4>Últimos reportes</h4>
							<fieldset className="filter-container">
								<div className="filter-container-one">
								<input className="form-control" type="text" onChange={this.handleChangeLastsFilter.bind(this)} value={this.state.lasts_filter}/>
								</div>
								<div className="filter-container-two">
								{ (!this.state.lasts_filtered) ? (
								<button className="btn btn-primary" onClick={()=>this.lastsFilter()}>Filtrar</button>
								) : (
								<button className="btn btn-warning" onClick={()=>this.removeLastsFilter()}>Deshacer filtro</button>
								) }
								</div>
							</fieldset>
							<ul className="list-group">
							{	lasts.map((l,i) => (
									<li className="list-group-item" key={i}>
										<div className="d-flex w-100 justify-content-between">
                                                                                	<h5 className="mb-1">{l.split(',')[2]} ({l.split(',')[1]})</h5>
                                                                                	<h5>{l.split(',')[3]}%</h5>
                                                                        	</div>
                                                                        	<p className="mb-1">{this.renderDate(l.split(',')[0])}</p>
									</li>
								) 
							)}
							</ul>
						</div>
						<div className="mb-3">
							<fieldset>
								<label htmlFor="dateInputReports" className="form-label">Fecha</label>
								<input id="dateInputReports" className="form-control" value={this.state.date} type="date" onChange={this.handleChangeDate.bind(this)}/>
							</fieldset>
							{buttonEnabled ? 
								<button className="btn btn-primary mt-3" onClick={()=>this.seeReport()}>Ver reporte</button>
							:  <button disabled className="btn btn-primary mt-3" onClick={()=>this.seeReport()}>Ver reporte</button>
							}
						</div>

							<fieldset className="filter-container">
								<div className="filter-container-one">
								<input className="form-control" type="text" onChange={this.handleChangeReportsFilter.bind(this)} value={this.state.reports_filter}/>
								</div>
								<div className="filter-container-two">
								{ (!this.state.reports_filtered) ? (
								<button className="btn btn-primary" onClick={()=>this.reportsFilter()}>Filtrar</button>
								) : (
								<button className="btn btn-warning" onClick={()=>this.removeReportsFilter()}>Deshacer filtro</button>
								) }
								</div>
							</fieldset>
						<ul className="list-group">
							{reports.map((ug,i) => (
                                                                <li className="list-group-item" key={i}>
                                                                        <div className="d-flex w-100 justify-content-between">
                                                                                <h5 className="mb-1">{ug.split(',')[2]} ({ug.split(',')[1]})</h5>
                                                                                <h5>{ug.split(',')[3]}%</h5>
                                                                        </div>
                                                                        <p className="mb-1">{this.renderDate(ug.split(',')[0])}</p>
                                                                </li>
                                                                )
                                                        )}
						</ul>
					</div>
				</div>
			</div>) : (null) }
		</section>
		);
	}
}
