import React from 'react';
import MsgError from './MsgError';

const NoMatch = ({location}) => (
	<React.Fragment>
		<MsgError message={"No se encuentra la ruta solicitada"}/>
	</React.Fragment>
);

export default NoMatch;
