import React,{useContext} from 'react';
import AppContext from '../context/app-context';


function BtnDot(){
	const context = useContext(AppContext);
	let chau = (value) => {
		context.setChecked(value);
	}
	if(!context.checked){
		return(
		<svg onClick={()=>chau(true)} xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="white" className="bi bi-three-dots" viewBox="0 0 16 16">
  			<path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"/>
		</svg>
		);
	}else{
	return(
		<svg onClick={()=>chau(false)} xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="white" className="bi bi-three-dots-vertical" viewBox="0 0 16 16">
  			<path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
		</svg>
	);
	}
}

export default BtnDot;
