import React,{Component} from 'react';
import Navigator from '../Navigator';
import {API_UPLOADS} from '../../config';


import AppContext from '../../context/app-context';
import NavigatorMobile from '../../components/NavigatorMobile';

function renderList(checked){
	if(checked){
		return(
			<NavigatorMobile/>
		);
	}
	return null;
}

export default class UploadSound extends Component{

	static contextType = AppContext;

	constructor(props){
		super();
		this.state = {
			selectedFile: null,
			loading: false,
			option: 'alive',
			error: null,
			uploading: false
		}
	}

	onFileChange = event => { 
      // Update the state 
      this.setState({ selectedFile: event.target.files[0], error: null, success: false  }); 
    }; 


	handleSubmit(e){
		this.setState({uploading:true});
		const formData = new FormData();

		formData.append('name', this.state.option);	
		formData.append('sound', this.state.selectedFile);

		fetch(`${API_UPLOADS}/sounds`, {
		  method: 'POST',
		  body: formData
		})
		.then(result => {
			if(result.ok){
				this.setState({uploading:false,selectedFile:null,success:true,error:null});
			}else{
				this.setState({error:'Ha ocurrido un problema',uploading:false,selectedFile:null,sucess:false});
			}
		})
		.catch(error => {
			this.setState({error:'Ha ocurrido un problema',uploading:false,selectedFile:null,success:false});
		});

	}

	handleOptionChange(e){
		this.setState({option:e.target.value});
	}

	render(){
		return(
			<main className="contenedor">
				{renderList(this.context.checked)}
				{(!this.context.checked) ? 
				(
				<div className="uploads-body">
						<div className="card">
							<div className="card-header">
								<h2>Subir sonido</h2>
							</div>
							<div className="card-body">
								<div className="mb-3">
								<label className="mb-3">Tipo de sonido</label>
								<select className="form-select" onChange={this.handleOptionChange.bind(this)} value={this.state.option}>
									<option value={"alive"}>Paquete vivo</option>
									<option value={"unreachable"}>Paquete inalcanzable</option>
									<option value={"high"}>Temperatura alta</option>
								</select>
								</div>
								<div className="mb-3">
									<label htmlFor="formFile" className="form-label">Seleccione el archivo</label>
  									<input className="form-control" type="file" id="formFile" onChange={this.onFileChange}/>
								</div>
							</div>
							<div className="card-footer">
								{this.state.uploading ? (
								<button disabled className="btn btn-primary mb-3" onClick={this.handleSubmit.bind(this)}>
								<div className="spinner-border text-primary"></div>
								</button>
								) : (
								<button className="btn btn-primary mb-3" onClick={this.handleSubmit.bind(this)}>Subir</button>
								)
								}

								{this.state.error ? (
								<div className="alert alert-danger">{this.state.error}</div>
								) : (null)
								}
								{this.state.success ? (
								<div className="alert alert-success">Se ha completado la subida del archivo</div>	
								): (null)
								}

							</div>
						</div>
				</div>
				) : (null)}
			</main>
		);
	}
}

/*
								<div className="mb-3">
									<label htmlFor="formFile" className="form-label">Seleccione el archivo</label>
  									<input className="form-control" type="file" id="formFile" value={this.state.selectedFile} onChange={this.onFileChange}/>
								</div>
*/
