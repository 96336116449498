import React,{useContext} from 'react';
import {Link} from 'react-router-dom';
import BtnSignout from './BtnSignout';
import BtnPush from './BtnPush';
import BtnDot from './BtnDot';
import logoPAS from '../paslogo.svg';

import AlarmSensors from './AlarmSensors';
import AlarmAlive from './AlarmAlive';
import AlarmUnreachable from './AlarmUnreachable';

import AppContext from '../context/app-context';
//import NavigatorMobile from './NavigatorMobile';

function renderName(nick){
	let aux;
	let name = '';
	aux = nick.split(' ');
	for(let i=0;i<aux.length;i++){
		name=`${name}${aux[i][0]}`
	}
	return name;
}

/*
function renderList(checked){
	if(checked){
		return(
			<NavigatorMobile/>
		);
	}
	return null;
}
*/

function Header(){
	let role=localStorage.getItem('role');
	const context = useContext(AppContext);
	if(role==='user'||role==='admin'){
		return(
		<nav className="bg-dark newnav">
			<div className="newnav-one">
				<div className="newnav-one-title">
					<Link onClick={()=>context.setChecked(false)} to={"/"} className="nav-link text-white">
						<img src={logoPAS} style={{"width":"50px"}} alt="pas logo"/> 
							Ping Alert System
					</Link>	
				</div>

				<div className="newnav-one-complement">
					<div className="newnav-one-complement-push">
					<BtnPush/>
					</div>
					<div className="newnav-one-complement-signout">
					<BtnSignout/>
					</div>
				</div>
				
			</div>
			<div className="newnav-two">
				<div className="newnav-two-nick">
					<h5 className="newnav-two-nick-text">{renderName(localStorage.getItem('email'))} </h5>
					<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="white" className="bi bi-person-circle" viewBox="0 0 16 16">
  						<path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
  						<path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
					</svg>
				</div>
				<BtnDot/>
				<div className="invisibles">
				<AlarmSensors/>
				<AlarmAlive/>
				<AlarmUnreachable/>
				</div>
			</div>
		</nav>
		);
	}
	return null;
}

export default Header;
