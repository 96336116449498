import React,{Component,createRef} from 'react';
import Map from 'ol/Map';
import View from 'ol/View';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer';
import {OSM} from 'ol/source';
import {fromLonLat} from 'ol/proj';
import {Style, Text,Circle as CircleStyle, Fill, Stroke} from 'ol/style';
import Point from 'ol/geom/Point';
import Feature from 'ol/Feature';
import VectorSource from 'ol/source/Vector';
import {API_BASE,endpointMonitor,endpoint3} from '../../config';
import socketIOClient from "socket.io-client";

import Select from 'ol/interaction/Select';

let vectorSource = new VectorSource({
})

let cache = {};

function vectorStyle(feature,scale){
                let name = feature.get('name');
		let scaleText = 1.6;
		let textAlign = 'center';
		let textBaseline = 'top';
		let offsetY=10;
		let status = feature.get('status');
			if(status==='A'){
				cache[name] = new Style({
					fill: new Fill({
                                        	color: 'rgba(255, 255, 255, 0.2)',
                                	}),
                                	stroke: new Stroke({
                                        	color: '#ffcc33',
                                        	width: 2,
                                	}),
                                	image: new CircleStyle({
                                        	radius: 7,
                                        	fill: new Fill({
                                                	color: '#008000',
                                        	}),
                                	}),
					text: new Text({
						text: name,
						overflow: true,
    						scale: [scaleText, scaleText],
    						textAlign,
    						textBaseline,
						offsetY
					})
				});
			}else{
				cache[name] = new Style({
					fill: new Fill({
                                                color: 'rgba(255, 255, 255, 0.2)',
                                        }),
                                        stroke: new Stroke({
                                                color: '#ffcc33',
                                                width: 2,
                                        }),
                                        image: new CircleStyle({
                                                radius: 7,
                                                fill: new Fill({
                                                        color: '#ff0000',
                                                }),
                                        }),
                                        text: new Text({
                                                text: name,
                                                overflow: true,
                                                scale: [scaleText, scaleText],
                                                textAlign,
                                                textBaseline,
						offsetY
                                        })
				});
			}
                return [cache[name]];
        }

function vStyle(feature){
	return vectorStyle(feature,0.6);
}

function sStyle(feature){
	return vectorStyle(feature,1);
}


let vectorLayer = new VectorLayer({
        source: vectorSource,
	style: vStyle
});

function photoContent(feature) {
	let contents=[];
      var keys = ['status','name','identi'];
      for (var i=0; i<keys.length; i++) {
        var key = keys[i];
        var value = feature.get(key);
	contents.push(value);
      }
      return contents;
}

export default class Mapa extends Component {
        constructor(){
                super();
		this.state = {
			ubications: [],
			mapa: null,
			notifications: [],
			socketMonitor: null,
			uStatus: [],
			loading: true
		}
                this.myRef = createRef();
        }
		
	getSites(){
		fetch(`${API_BASE}/sites/withoutmap`)
                .then(response => response.json())
                .then(data => {
			let fea;
                        for(let i=0;i<data.length;i++){
				fea = new Feature({
					geometry: new Point(
                                                fromLonLat(
                                                [data[i].coordinates.longitude,
                                                data[i].coordinates.latitude]
                                                )
                                        )
				});
				fea.set('name',data[i].name);
				fea.set('status',data[i].status);
				fea.set('identi',data[i]._id);
                                vectorSource.addFeature(fea);
                        }
			this.setState({sites:data});
		});
	}

	componentWillUnmount(){
		this.state.mapa.getLayers().forEach(layer => {
    			this.state.mapa.removeLayer(layer);
		});
		let features = vectorLayer.getSource().getFeatures();
    		features.forEach((feature) => {
        		vectorLayer.getSource().removeFeature(feature);
    	});
		this.state.socketMonitor.close();
	}
	
	componentDidUpdate(prevProps,prevState){
		if ((this.state.socketMonitor !== prevState.socketMonitor) && (!prevState.socketMonitor)) {
            this.state.socketMonitor.emit("subscriberMonitor");
			this.state.socketMonitor.on("FromAPI", data => {
				//console.log('FROM API', data);
				let features = vectorLayer.getSource().getFeatures();
				features.forEach((feature) => {
					vectorLayer.getSource().removeFeature(feature);
				});
				let fea;
				for(let i=0;i<data.length;i++){
					fea = new Feature({
						geometry: new Point(
							fromLonLat(
								[data[i].coordinates.longitude,
								data[i].coordinates.latitude]
							)
						)
					});
					fea.set('name',data[i].name);
					fea.set('status',data[i].status);
					fea.set('identi',data[i]._id);
					vectorSource.addFeature(fea);
				 }
           })
      	}
	}	
	
        componentDidMount(){
		//this.getSites();		
		let fea;
		for(let i=0;i<this.props.sites.length;i++){
                                fea = new Feature({
                                        geometry: new Point(
                                                fromLonLat(
                                                [this.props.sites[i].coordinates.longitude,
                                                this.props.sites[i].coordinates.latitude]
                                                )
                                        )
                                });
                                fea.set('name',this.props.sites[i].name);
                                fea.set('status',this.props.sites[i].status);
                                fea.set('identi',this.props.sites[i]._id);
                                vectorSource.addFeature(fea);
                }
	
		let X = -57.5177029;
		let Y = -35.0805371;

		let raster = new TileLayer({
  			source: new OSM(),
		});

                let mapita = new Map({
                        target: this.myRef.current,
                        layers: [raster,vectorLayer],
                        view: new View({
                                center: fromLonLat([X,Y]),
                                zoom: 10
                        })
                });

		let select = new Select({
      			layers: [vectorLayer],
      			style: sStyle
    		});

		mapita.addInteraction(select);

		let selectedFeatures = select.getFeatures();

		if(localStorage.getItem('role')==='admin'){
    			selectedFeatures.on('add', function(event) {
      				let feature = event.target.item(0);
      				let content = photoContent(feature);
					window.location.href=`${endpoint3}/editsite/${content[2]}`;
    			});
		}
	
		this.setState({mapa:mapita});

		this.setState({socketMonitor: socketIOClient(endpointMonitor)});
        }
        render(){
                return(
			<React.Fragment>
				<div className="card">
					<div className="card-header">
						<h2>Monitor</h2>
					</div>
					<section className="card-body">
                                		<div ref={this.myRef} style={{"width":"100%","height":"100vh"}} className="mb-3"/>
					</section>
				</div>
			</React.Fragment>
                );
        }
}
