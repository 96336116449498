import React from 'react';

export default function Rect(props){
	const indice=props.indice;
	const name=props.data.name;
	const horas=props.data.horas;
	return(
		<g>
                	<text fontSize={"20"} x="10" y={50*(indice+1)} fill={props.color}>{name}</text>
                	<rect x="150" y={50*(indice+1)-30} width={horas*30+10} height="50" fill={props.color} stroke="white" strokeWidth={3}/>
                	<text fontSize={"20"} x={150+(horas*30)+50} y={50*(indice+1)} fill={props.color}>{horas} hs.</text>
                </g>
	);
}
