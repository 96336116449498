import React,{ Component } from 'react';
import { endpoint } from '../../config';
import Loading from '../../components/Loader';
import Aside from '../../components/Navigator';
//import renderBarChart from './renderBarChart';
import Bar from './Bar';


import AppContext from '../../context/app-context';
import NavigatorMobile from '../../components/NavigatorMobile';

function renderList(checked){
	if(checked){
		return(
			<NavigatorMobile/>
		);
	}
	return null;
}

export default class Statistics extends Component {
	
	static contextType = AppContext;
	constructor(props){
		super();
		this.state = {
			kit: "",
			kits: null,
			isLoaded: false,
			datos: [],
			loading: true,
		};
		this.handleChangeKit = this.handleChangeKit.bind(this);
	}

	handleChangeKit(e){ this.setState({kit: e.target.value}); }

  getSites(){
    fetch(`${endpoint}/monitor/ips`,{headers:{
      'Content-Type': 'application/json','authorization': `${localStorage.getItem('token')}`
    }})
      .then(res => res.json())
      .then(
        (result) => {
		console.log('resultado',result);
	let kits = [];
	for(let i=0;i<result.length;i++){
		let k=result[i].split(',');
		kits.push({ip:k[0],name:k[1],type:k[2]});
	}
	this.setState({kits,kit:kits[0].ip});
      })
    //.then(()=>this.getStatistics(this.state.kit))
      .catch(err => console.log(err))
  }

	getStatistics(ip){
	this.setState({loading:true});
    fetch(`${endpoint}/statistics/${ip}`,{headers:{
      'Content-Type': 'application/json','authorization': `${localStorage.getItem('token')}`
    }})
      .then(res => res.json())
      .then(
        (result) => {
	let datos = [];
	for(let i=0;i<result.length;i++){
		let seconds = parseInt(result[i].split('T')[1])
		let hours = (seconds/3600);
		datos.push({name:result[i].split('T')[0],horas:hours.toFixed(2)});
	}
	this.setState({datos,loading:false});
      })
      .catch(err => console.log(err))
  }


  componentDidMount(){
    this.getSites();
  }

	componentDidUpdate(prevProps,prevState){
		if(prevState.kit!==this.state.kit){
			this.setState({loadingnotg:true});
			this.getStatistics(this.state.kit);
		}
	}


  	render(){
      	return(
          <main className="contenedor">

			{renderList(this.context.checked)}
			{(!this.context.checked) ? (
            <div className="cuerpo mb-3">

              <div className="card">
                <div className="card-header">
                  <h2>Estadísticas</h2>
                </div>
		{(this.state.kits&&this.state.kits.length>0) ? (
		<div className="card-body">
			<h4>EQUIPOS</h4>
			<label>
				Seleccione el equipo
				<select className="form-select" value={this.state.kit} onChange={this.handleChangeKit}>
					{this.state.kits.map( k => <option value={k.ip}>{k.name} ({k.ip}) tipo: {k.type}</option>)}
				</select>
			</label>
		</div>
		): (null)}
		<div className="mt-3" style={{"display":"flex","justifyContent":"center","alignItems":"center","width":"100%","minHeight":"60vh"}}>
                        {this.state.loading ? (
                <div className="spinner-border text-success" role="status">
                </div>
                ) : (<Bar data={this.state.datos}/>) }
                </div>
              </div>
            </div>) : (null) }
          </main>
      );
  }
}
