import React,{Component} from 'react';
import {API_BASE} from '../../config';
import Navigator from '../Navigator';

import AppContext from '../../context/app-context';
import NavigatorMobile from '../../components/NavigatorMobile';

function renderList(checked){
	if(checked){
		return(
			<NavigatorMobile/>
		);
	}
	return null;
}

export default class NewSite extends Component {

	static contextType = AppContext;
	constructor(){
		super();
		this.state = {
			name: "",
			ip: ""
		};
		this.handleChangeName = this.handleChangeName.bind(this);
		this.handleChangeIP = this.handleChangeIP.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}
	handleChangeName(event){ this.setState({name: event.target.value}); }
	handleChangeIP(e){ this.setState({ip: e.target.value}); }
	handleSubmit(e){
		let data = {
			name: this.state.name,
			ip: this.state.ip
		}
		fetch(`${API_BASE}/sites`, {
  		method: "POST",
  			body: JSON.stringify(data),
  			headers: {"Content-type": "application/json"}
		})
		.then(response => {
			if(response.ok){
				this.props.history.push('/sites');
			}
		}) 
		.catch(err => console.log(err));
		e.preventDefault();
	}
	render(){
		return(
		<section className="contenedor">

			{renderList(this.context.checked)}
			{(!this.context.checked) ? (
			<div className="cuerpo mb-3">
				<div className="card">
					<div className="card-header">
						<h2>Nuevo sitio</h2>
					</div>
					<div className="card-body">
						<form onSubmit={this.handleSubmit}>
							<fieldset>
								<label htmlFor="nameInputNewSite" className="form-label">Nombre</label>
								<input id="nameInputNewSite" className="form-control" type="text" value={this.state.name} onChange={this.handleChangeName}/>
							</fieldset>
							<button className="btn btn-primary mt-3">Generar nuevo sitio</button>
						</form>
					</div>
				</div>
			</div>) : (null) }
		</section>
		);
	}
}
